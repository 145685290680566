import { default as _watchRTC } from '@testrtc/watchrtc-sdk';
import {
	DEFAULT_AUDIO_TRANSCEIVER_SEND_ENCODINGS,
	DEFAULT_VIDEO_TRANSCEIVER_SEND_ENCODINGS,
	REFRESH_ICE_SERVERS_INTERVAL,
} from './fallbacks';
import { useLocalMedia } from './localMedia';
import usePeerConnection from './peerConnection';
import getSignalingClient from './signaling';
import { ISignalingClient } from './signaling/ISignalingClient';
import {
	DatachannelInit,
	IceTransportProtocol,
	LocalMedia,
	RemoteMedia,
	WebRTCSessionConfiguration,
} from './types';

/**
 * WebRTC session
 */
export default class WebRTCSession {
	signalingClient: ISignalingClient;
	peerConnection: RTCPeerConnection;
	localMedia: LocalMedia[] = [];
	remoteMedia: RemoteMedia[] = [];
	dataChannels: RTCDataChannel[] = [];
	remoteDataChannels: RTCDataChannel[] = [];
	watchRTC: any = _watchRTC;
	refreshIceServersLoopId: ReturnType<typeof setInterval>;

	setLocalMedia: (value: LocalMedia[]) => void = (value) => (this.localMedia = value);
	setRemoteMedia: (value: RemoteMedia[]) => void = (value) => (this.remoteMedia = value);
	setDataChannels: (value: RTCDataChannel[]) => void = (value) => (this.dataChannels = value);
	setRemoteDataChannels: (value: RTCDataChannel[]) => void = (value) =>
		(this.remoteDataChannels = value);

	/**
	 * Gets local media
	 */
	getLocalMedia: () => Promise<void> = async () => {
		// Get local media
		const _localMedia = await useLocalMedia(
			this.configuration.localMediaConstraints,
			this.configuration.preferredDevices ?? null,
			this.configuration.onLocalMediaError!
		);
		this.setLocalMedia([_localMedia]);
		if (_localMedia.error) throw new Error(_localMedia.error.message);
	};

	/**
	 * Gets signaling client
	 */
	private getSignalingClient: () => void = () => {
		console.log('STEP: Getting signaling client');
		// Use signaling client
		this.signalingClient = getSignalingClient(this.configuration);
		this.signalingClient.onOpen = async () => {
			if (!this.signalingClient.isClosed) return;
			// Get user media
			await this.getLocalMedia();
			// Handle transceivers
			this.handleTransceivers();
			// Create an SDP offer to send to the master
			const offer = await this.peerConnection.createOffer({
				offerToReceiveVideo: true,
				offerToReceiveAudio: true,
			});
			console.log('SDP OFFER', offer);
			await this.peerConnection.setLocalDescription(offer);
			// Set preferred params on RTCRtpSender for video
			await this.setSendingPreferredParams();
			this.signalingClient.sendSDPOffer(this.peerConnection.localDescription!);
			this.signalingClient.isClosed = false;
		};
		this.signalingClient.onSDPOffer = async (offer, remoteClientId) => {
			// Handle sdp offer
			if (this.configuration.onSDPOfferExtensions)
				await this.configuration.onSDPOfferExtensions[offer.type as string](offer.sdp);
		};
		this.signalingClient.onSDPAnswer = async (answer) => {
			// Handle sdp answer
			if (
				this.configuration.onSDPAnswerExtensions &&
				Object.keys(this.configuration.onSDPAnswerExtensions).includes(answer.type)
			)
				await this.configuration.onSDPAnswerExtensions[answer.type as string]();
			else {
				console.log('SDP ANSWER', answer);
				if (this.peerConnection.signalingState === 'have-local-offer')
					this.peerConnection.setRemoteDescription(answer);
			}
		};
		console.log('SETTING UP ON ICE CANDIDATE');
		this.signalingClient.onIceCandidate = (candidate) => {
			console.log('NEW ICE CANDIDATE', candidate);
			this.peerConnection.addIceCandidate(candidate);
		};
		this.signalingClient.onClose = () => {
			if (!this.signalingClient.isClosed) {
				console.info('Signaling client closed, re-opening...');
				this.signalingClient.open();
			}
		};
		this.signalingClient.onError = (error) => {
			if (!this.signalingClient.isClosed) {
				console.info('Signaling client had an error, re-opening...', error);
				this.signalingClient.open();
			}
		};
	};

	/**
	 * Opens signaling client
	 */
	private openSignalingClient: () => void = async () => {
		await this.signalingClient.init();
		// Config WatchRTC
		this.watchRTC?.setConfig({
			...this.configuration.watchRTC,
			signalingServerUrls:
				this.signalingClient.iceServers.map((iceServer) => iceServer.urls).flat() ?? null,
		});
		this.watchRTC?.connect();
	};

	/**
	 * Gets peer connection configuration
	 */
	private getPeerConnectionConfiguration: () => RTCConfiguration = () =>
		({
			iceServers: this.signalingClient.iceServers,
			iceTransportPolicy: ((policy) => (!['all', 'relay'].includes(policy) ? 'all' : policy))(
				this.configuration.iceTransportPolicy
			),
		} as RTCConfiguration);

	/**
	 * Gets peer connection
	 */
	private getPeerConnection: () => void = () => {
		console.log('STEP: Getting peer connection', this.signalingClient);
		// Use peer connection
		this.peerConnection = usePeerConnection(this.getPeerConnectionConfiguration());

		// Send any ICE candidates to the other peer
		this.peerConnection.addEventListener('icecandidate', ({ candidate }) => {
			const isSupportedCandidateType = (type: string) =>
				this.configuration.iceTransportPolicy === 'all' ||
				((type) => (['prflx', 'srflx'].includes(type) ? 'reflex' : type))(type) ===
					this.configuration.iceTransportPolicy;
			const isSupportedCandidateProtocol = (protocol: IceTransportProtocol, type: string) =>
				!this.configuration.iceTransportProtocol ||
				this.configuration.iceTransportProtocol === 'all' ||
				type === 'relay' ||
				protocol === this.configuration.iceTransportProtocol;
			if (
				candidate &&
				candidate.type &&
				isSupportedCandidateType(candidate.type) &&
				isSupportedCandidateProtocol(candidate?.protocol!, candidate?.type!)
			) {
				console.log('GENERATED ICE CANDIDATE', candidate);
				// send the ICE candidates as they are generated.
				this.signalingClient.sendICECandidate(candidate);
			}
		});
		// As remote tracks are received
		this.peerConnection.addEventListener('track', (e: RTCTrackEvent) => {
			const { streams, track, transceiver } = e;
			this.setRemoteMedia([
				...this.remoteMedia,
				{
					streams,
					track,
					transceiver,
				},
			]);
		});
		// Add connection state change listeners
		// connectionstatechange
		this.peerConnection.onconnectionstatechange = (ev) => {
			// if (this.peerConnection.iceConnectionState === 'disconnected') this.iceRestart();
			this.configuration?.onOpenConnectionListeners?.connectionstatechange(
				this.peerConnection.connectionState
			);
		};

		// iceconnectionstatechange
		this.peerConnection.oniceconnectionstatechange = (ev) => {
			// if (this.peerConnection.iceConnectionState === 'disconnected') this.iceRestart();

			this.configuration?.onOpenConnectionListeners?.iceconnectionstatechange(
				this.peerConnection.iceConnectionState
			);
		};

		// icegatheringstatechange
		this.peerConnection.onicegatheringstatechange = (ev) =>
			this.configuration?.onOpenConnectionListeners?.icegatheringstatechange(
				this.peerConnection.iceGatheringState
			);

		// signalingstatechange
		this.peerConnection.onsignalingstatechange = (ev) =>
			this.configuration?.onOpenConnectionListeners?.signalingstatechange(
				this.peerConnection.signalingState
			);

		// negotiationneeded
		this.peerConnection.onnegotiationneeded = () =>
			this.configuration?.onOpenConnectionListeners?.negotiationneeded();

		// ERROR
		this.peerConnection.onicecandidateerror = (ev) =>
			this.configuration?.onOpenConnectionListeners?.icecandidateerror(ev);
	};

	/**
	 * Sets the refresh ice servers loop
	 */
	private setRefreshIceServers: () => void = () => {
		clearTimeout(this.refreshIceServersLoopId!);
		this.refreshIceServersLoopId = setInterval(
			this.refreshIceServers,
			REFRESH_ICE_SERVERS_INTERVAL
		);
	};

	/**
	 * Refreshes ice servers and set peer configuration
	 */
	private refreshIceServers: () => Promise<void> = async () => {
		console.info('Refreshing ice servers');
		await this.signalingClient.getIceServers(this.configuration.iceTransportProtocol);
		this.peerConnection.setConfiguration(this.getPeerConnectionConfiguration());
	};

	/**
	 * Gets remote data channels from peer connection
	 */
	private getRemoteDataChannels: () => void = () => {
		console.log('STEP: Getting remote datachannels');
		this.peerConnection.ondatachannel = (e: RTCDataChannelEvent) => {
			if (this.configuration.ondatachannel) {
				const datachannelMessageHandler = this.configuration.ondatachannel.find(
					(datachannelMessageHandler) => datachannelMessageHandler.label === e.channel.label
				);
				if (datachannelMessageHandler?.onmessage)
					e.channel.onmessage = datachannelMessageHandler.onmessage as
						| ((this: RTCDataChannel, ev: MessageEvent<any>) => any)
						| null;
				if (datachannelMessageHandler?.onopen) e.channel.onopen = datachannelMessageHandler.onopen;
			}
			this.setRemoteDataChannels([...this.remoteDataChannels, e.channel]);
		};
	};

	/**
	 * Creates data channels in peer connection
	 */
	private createDataChannels: () => void = () => {
		console.log('STEP: Creating datachannel');
		if (this.configuration.dataChannels)
			this.configuration.dataChannels.forEach((datachannelDef: DatachannelInit) => {
				const datachannel = this.peerConnection.createDataChannel(
					datachannelDef.label,
					datachannelDef.datachannelDict
				);
				if (datachannelDef.onmessage) datachannel.onmessage = datachannelDef.onmessage;
				if (datachannelDef.onopen) datachannel.onopen = datachannelDef.onopen;
				this.setDataChannels([...this.dataChannels, datachannel]);
			});
	};

	private handleTransceivers: () => void = () => {
		// Handle peer connection transceivers
		this.localMedia.forEach((localMedia, index) => {
			localMedia.media?.stream?.getTracks().forEach((track) => {
				switch (track.kind) {
					case 'video':
						this.localMedia[index] = {
							...localMedia,
							transceiver: this.peerConnection.addTransceiver(track, {
								direction: 'sendrecv',
								sendEncodings: [DEFAULT_VIDEO_TRANSCEIVER_SEND_ENCODINGS],
								streams: [localMedia.media?.stream as MediaStream],
							}),
						};
						this.setLocalMedia(this.localMedia);
						break;
					case 'audio':
						this.peerConnection.addTransceiver(track, {
							direction: 'sendrecv',
							sendEncodings: [DEFAULT_AUDIO_TRANSCEIVER_SEND_ENCODINGS],
							streams: [localMedia.media?.stream as MediaStream],
						});
						break;
				}
			});
		});

		this.peerConnection.addTransceiver('video', {
			direction: 'recvonly',
			streams: [],
		});

		const supportsSetCodecPreferences =
			window.RTCRtpTransceiver && 'setCodecPreferences' in window.RTCRtpTransceiver.prototype;
		if (supportsSetCodecPreferences) {
			const { codecs } = RTCRtpSender.getCapabilities('video') as RTCRtpCapabilities;
			console.log('Codecs supported by this device: ', codecs);
			let h264Codecs = [...codecs.filter((codec) => codec.mimeType === 'video/H264')];
			// The RTCRtpSender.getCapabilities API lists support for some profiles which will not work on newer versions of Chrome
			let supportedH264Codecs = h264Codecs.filter((item) => {
				// Extract profile-level-id from sdpFmtpLine
				if (!item.sdpFmtpLine) throw new Error('H264 sdpFmtpLine is undefined');

				let profileLevelId = item.sdpFmtpLine
					.split(';')
					.find((param) => param.startsWith('profile-level-id='));

				// These profiles are known to be supported
				return (
					profileLevelId === 'profile-level-id=4d001f' ||
					profileLevelId === 'profile-level-id=42001f' ||
					profileLevelId === 'profile-level-id=42e01f'
				);
			});
			h264Codecs = supportedH264Codecs;
			if (navigator.userAgent.includes('Android')) {
				// Newer Chrome versions on Android also claim to support H264, but this is completely false
				h264Codecs = [];
			}
			let vp8Codecs = [...codecs.filter((codec) => codec.mimeType === 'video/VP8')];
			let rearrangedCodecs = [...h264Codecs, ...vp8Codecs];
			console.log('Codecs useable by robot: ', rearrangedCodecs);

			const transceivers = [
				...this.peerConnection
					.getTransceivers()
					.filter((t) => t.sender.track?.kind === 'video' || t.receiver.track?.kind === 'video'),
			];
			if (transceivers) {
				transceivers.forEach((transceiver: any) =>
					transceiver.setCodecPreferences(rearrangedCodecs)
				);
				console.log('Codec preferences has been set on transceiver');
			} else {
				console.error('transceiver has not been set up on peer connection yet');
			}
		} else {
			console.warn('Unfortunately, specifying preferred codec is not supported');
		}

		this.localMedia.forEach((localMedia) =>
			localMedia.media?.stream
				?.getTracks()
				?.forEach((track, i) =>
					this.watchRTC.mapTrack(track.id!, track.label ?? `pilot_${track.kind}_${i}`)
				)
		);
	};

	/**
	 * Sets preferred params for RTCRtpSenders for video
	 */
	private setSendingPreferredParams: () => Promise<void[]> = async () => {
		return Promise.all(
			this.peerConnection.getTransceivers().map(async (t) => {
				try {
					const params = t.sender.getParameters();
					let newParams = {
						...params,
						encodings: params.encodings.length
							? params.encodings.map((encoding) => ({
									...encoding,
									...DEFAULT_VIDEO_TRANSCEIVER_SEND_ENCODINGS,
							  }))
							: [DEFAULT_VIDEO_TRANSCEIVER_SEND_ENCODINGS],
						degradationPreference: 'maintain-resolution',
					} as any;
					if (t.sender.track?.kind === 'audio') {
						newParams = {
							...params,
							encodings: params.encodings.length
								? params.encodings.map((encoding) => ({
										...encoding,
										...DEFAULT_AUDIO_TRANSCEIVER_SEND_ENCODINGS,
								  }))
								: [DEFAULT_AUDIO_TRANSCEIVER_SEND_ENCODINGS],
						} as any;
					}

					return t.sender.setParameters(newParams);
				} catch (error) {
					console.warn(`Error -> peerConnection.transceiver.sender.setParameters`, error);
				}
			})
		);
	};

	/**
	 * Ice restart
	 */
	public iceRestart: () => void = async () => {
		console.log('SENDING ICE RESTART');
		// if (this.peerConnection.signalingState === 'have-local-offer') {
		// 	console.error('Signaling client is in have-local-offer state => discarding ice restart');
		// 	return;
		// }
		await this.refreshIceServers();
		this.watchRTC.addEvent({
			type: 'global',
			name: 'iceRestart',
			parameters: {},
		});
		const offer = await this.peerConnection.createOffer({
			iceRestart: true,
			offerToReceiveVideo: true,
			offerToReceiveAudio: true,
		});
		console.log('ICE RESTART OFFER', offer);
		try {
			await this.peerConnection.setLocalDescription(offer);
		} catch (e) {
			console.error(e, offer, this.peerConnection.localDescription);
		}
		return this.signalingClient.sendSDPOffer(this.peerConnection.localDescription!);
	};

	/**
	 * Opens the session
	 */
	public open: () => void = async () => {
		// Setup connection
		this.getSignalingClient();
		// Open the signaling client
		await this.openSignalingClient();
		this.getPeerConnection();
		this.getRemoteDataChannels();
		this.createDataChannels();
		this.setRefreshIceServers();
	};

	/**
	 * Closes the session
	 */
	public close: (reason?: string) => void = (reason = '') => {
		if (this.configuration.watchRTC)
			this.watchRTC.addEvent({
				type: 'global',
				name: 'sessionEnded',
				parameters: { reason },
			});
		clearTimeout(this.refreshIceServersLoopId!);
		if (this.signalingClient) this.signalingClient.close();
		if (this.peerConnection) this.peerConnection.close();
		if (this.localMedia)
			this.localMedia.forEach((localMedia) =>
				localMedia?.media?.stream?.getTracks().forEach((track) => track.stop())
			);
		if (this.remoteMedia) this.remoteMedia.forEach((media) => media.track.stop());
	};

	constructor(readonly configuration: WebRTCSessionConfiguration) {
		if (configuration.watchRTC) {
			this.watchRTC.init();
		}
	}
}
